import React from "react";
import { BaseLayout } from "../components/views/layouts/BaseLayout";
import { ViewMoreButton } from "../components/parts/ViewMoreButton";
import { BcItem } from "../components/views/Breadcrumb";

const bcList: BcItem[] = [{ href: "", text: "CONTACT" }];

const Thanks = () => {
  return (
    <BaseLayout
      bcList={bcList}
      showOnlineShopButton={false}
      pageUrl="/thanks"
      pageTitle="お問い合わせ完了"
      pageDescription=""
      isNoIndexPage={true}
    >
      <div className="pt-[100px] md:pt-[180px] px-5">
        <h1
          className="text-xl md:text-[28px] tracking-[0.1em]
          md:tracking-[.12em] font-bold text-center mb-5 md:mb-[74px] antialiased"
        >
          お問い合わせが完了しました
        </h1>
        <p className="text-center mb-10 md:mb-[74px]">
          お問合せありがとうございます。
          <br />
          ご入力いただきましたメールアドレス宛に
          <br className="md:hidden" />
          ご連絡いたしますので、
          <br className="hidden md:block" />
          今しばらくお待ちください。
        </p>
        <p className="text-center">
          なお、ご入力いただいたメールアドレス宛に
          <br />
          送信完了をお知らせするメールをお送りしておりますが、
          <br />
          <span className="text-primary text-[1.1em]">
            メールが届かない場合は、
            <br className="md:hidden" />
            送信エラー等の可能性がございます。
          </span>
          <br />
          大変お手数ですが、下記メールアドレス宛に、
          <br className="md:hidden" />
          直接メールをお送りいただけますと幸いです。
        </p>
        <div className="my-[30px] md:my-[60px] text-center">
          <a
            href="mailto:log.coffee.roasters@gmail.com"
            className="text-green text-md md:text-xl font-bold antialiased"
          >
            log.coffee.roasters@gmail.com
          </a>
        </div>
        <p className="text-center mb-[40px] md:mb-[60px]">
          今後とも、LOG COFFEE ROASTERSを
          <br />
          何卒よろしくお願いいたします。
        </p>
        <ViewMoreButton href="/" text="TOPへ" isPrimary={true} />
      </div>
    </BaseLayout>
  );
};

export default Thanks;
